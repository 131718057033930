import { Controller } from '@hotwired/stimulus';
import { leave, toggle } from 'el-transition';

// Connects to data-controller="logged-in-header"
export default class extends Controller {
  static targets = ['userMenuDropdown', 'mobileMenu', 'hamburgerIcon', 'closeIcon'];

  connect() {
    window.addEventListener('click', (e) => {
      if (e.target.id !== 'profileMenuButton') {
        leave(this.userMenuDropdownTarget);
      }
    });
  }

  disconnect() {
    window.removeEventListener('click', (e) => {
      if (e.target.id !== 'profileMenuButton') {
        leave(this.userMenuDropdownTarget);
      }
    });
  }

  buttonClicked() {
    toggle(this.userMenuDropdownTarget);
  }

  toggleMenu() {
    toggle(this.mobileMenuTarget);

    if (this.hamburgerIconTarget.classList.contains('hidden')) {
      this.closeIconTarget.classList.add('hidden');
      this.hamburgerIconTarget.classList.remove('hidden');

    } else {
      this.closeIconTarget.classList.remove('hidden');
      this.hamburgerIconTarget.classList.add('hidden');
    }
  }
}
